#include "blend/add.glsl"
#include "blend/average.glsl"
#include "blend/colorDodge.glsl"
#include "blend/difference.glsl"
#include "blend/glow.glsl"
#include "blend/hardMix.glsl"
#include "blend/linearBurn.glsl"
#include "blend/linearLight.glsl"
#include "blend/negation.glsl"
#include "blend/phoenix.glsl"
#include "blend/reflect.glsl"
#include "blend/softLight.glsl"
#include "blend/vividLight.glsl"
#include "blend/colorBurn.glsl"
#include "blend/darken.glsl"
#include "blend/exclusion.glsl"
#include "blend/hardLight.glsl"
#include "blend/lighten.glsl"
#include "blend/linearDodge.glsl"
#include "blend/multiply.glsl"
#include "blend/overlay.glsl"
#include "blend/pinLight.glsl"
#include "blend/screen.glsl"
#include "blend/subtract.glsl"
#include "blend/color.glsl"
#include "blend/hue.glsl"
#include "blend/luminosity.glsl"
#include "blend/saturation.glsl"